:root {
  color-scheme: light dark;
}

body {
  text-align: center;
  font-family: -apple-system, ui-sans-serif, system-ui, Arial, Helvetica, sans-serif;
  color: #333;
  margin: 40px 0;
}

a {
  text-decoration: none;
  color: hsl(210, 90%, 45%);

  &:hover { text-decoration: underline; }
}

header {
  margin-top: 70px;
  margin-bottom: 40px;

  h1 {
    font-size: 28pt;
    font-weight: 500;
    margin-bottom: 15px;

    a { color: #333; }
  }

  h2 {
    font-size: 12pt;
    font-weight: 500;
  }
}

nav {
  margin-bottom: 60px;

  a {
    margin-left: 2px;
    margin-right: 2px;
  }
}

.language {
  margin-bottom: 60px;

  h2 {
    display: inline-block;
    border: 1px solid #aaa;
    padding: 8px 15px;
    border-radius: 8px;
    min-width: 100px;
    font-size: 16pt;
    font-weight: 500;
    box-shadow: 0 0 2px rgba(0,0,0,0.2);
  }
}

.projects {
  list-style-type: none;
  padding-left: 0;
  width: 525px;
  margin: 10px auto;

  li {
    border: 1px solid #ccc;
    border-radius: 8px;
    margin: 20px 0px;
    padding: 2px 16px;
    line-height: 125%;
    text-align: left;

    &.official {
      border-color: hsl(210, 90%, 45%);
      box-shadow: 0 0 2px hsla(210, 90%, 45%, 40%);
      background-color: hsla(210, 90%, 45%, 3%);
    }

    .title { position: relative; padding-right: 35px; }
    .project-name { font-size: 14pt; font-weight: 500; margin-right: 2px; }
    .author { font-size: 11pt; vertical-align: bottom; }
    .author a { color: #333; }
    .author .dot { margin-right: 2px; }
    .butterfly { margin-left: 4px; }
    .stars { position: absolute; right: 0px; font-size: 10pt; color: #666; }
    .stars .count { font-size: 11pt;  }

    .description { line-height: 145%; font-size: 11pt; }

    .stats { margin-top: 15px; font-size: 11pt; }
    .stats img { position: relative; top: 2px; margin-right: 2px; }
    .stats .separator { margin: 0px 5px; font-size: 13pt; }
    .description + .stats { margin-top: 18px; border-top: 1px solid #ddd; padding-top: 15px; }
    .license { border: 1px solid #bbb; border-radius: 5px; padding: 1px 5px; font-size: 10pt; white-space: nowrap; }
    .license.unknown { border-color: #fa4; }
  }
}

footer {
  font-size: 10pt;
  margin: 70px 0 40px;
}

#github {
  position: fixed;
  top: 15px;
  right: 15px;
  z-index: 10;

  img {
    width: 24px;
    opacity: 0.4;
  }

  a:hover img {
    opacity: 0.6;
  }
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #0d1117;
    color: #ccc;
  }

  header {
    h1 a { color: #ddd; }
  }

  .language {
    h2 { border-color: #666; background-color: rgba(255, 255, 255, 0.05); }
  }

  .projects li {
    border-color: #666;
    background-color: rgba(255, 255, 255, 0.02);

    .author a { color: #888; }
    .stars { color: #888; }
    .description + .stats { border-top-color: #444; }
    .separator { color: #666; }
    .license { border-color: #666; }
    .license.unknown { border-color: #a62; }
    .stats img { filter: invert(1); opacity: 0.5; }
  }

  #github {
    img { filter: invert(1); opacity: 0.5; }
    a:hover img { opacity: 0.75; }
  }
}
